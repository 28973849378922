import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom'; // No need for Router here
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './Home';


function App() {

  useEffect(() => {
    AOS.init({
      duration: 600, // Animation duration
      easing: 'ease-out', // Easing function
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
     <div className="App">
        <Routes>
          <Route path="/" element={<Home/>} />         
        </Routes>
      </div>

  );
}

export default App;
