import React, { useState, useEffect } from "react";
import "./styling/home.css";
import "./styling/mobile.css";
import logo from "./assets/logo.png";
import ImageSlider from "./Slider";
import mail from "./assets/mail.svg";
import phone from "./assets/phone.svg";
import octo from "./assets/octo.JPG";
import bg from './assets/foodMs.webp';
import insta from './assets/insta.svg';

function Home() {
  const [fade, setFade] = useState("fade-in");

//   window.addEventListener('scroll', () => {
//     const wrapper = document.getElementById('homeWrapper');
    
//     // Check if window width is smaller than 800px
//     if (window.innerWidth < 800) {
//       // Apply the parallax effect
//       wrapper.style.backgroundPositionY = `${window.scrollY * 0.5}px`;
//     } 
//   });
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setFade("fade-out");
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer when component unmounts
  }, []);

  return (
    <div id="homeWrapper">
      <div id="colorDiv">
        <img src={bg} alt=""></img>
      </div>
      <div id="logoWrapper">
        <img src={logo} alt="Satiata Logo"></img>
        <p>A new restaurant concept by Pulcinos</p>
      </div>

      <div className={fade} id="spacer">
        <img data-aos="fade-up" src={logo} alt=""></img>
      </div>

      <div id="bottomWrapper">
        <h1>WELKOM BIJ SATIATA</h1>
      </div>

      <div id="mainWrapper">

        <div data-aos="zoom-in-left" id="contentWrapper">

          <div id="textWrapper">

            <div>

                <img src={octo} alt=""></img>

            </div>

            <div>
          <p>
            Satiata is het kleine zusje van Pulcinos, gelegen aan de
            Nieuwlandstraat in Tilburg. In onze gezellige, intieme zaak serveren
            we handgemaakte, verse gerechtjes die perfect te combineren zijn met
            een groot assortiment aan wijnen. De wijnen komen van onze eigen
            slijterij, pal naast de deur.
          </p>

          <p>
            Bij Satiata draait alles om genieten in een relaxte sfeer. Naast de
            vele wijnen kun je hier ook proeven van onze eigen Pulcinos likeuren
            en natuurwijnen. Kom langs voor een heerlijke avond vol smaken en
            gezelligheid!
          </p>
          <p>
            Bij Satiata ben je welkom voor lunch, diner of gewoon een borrel met
            een hapje.
          </p>

          <a href={`${process.env.PUBLIC_URL}/menu.pdf`} target="_blank" rel="noopener noreferrer">
          <button id="menuButton">Bekijk Menu</button>
          </a>

          </div>

          </div>  

          <ImageSlider />

          <div data-aos="zoom-in-up" id="addressWrapper">
            <div id="mapsWrapper">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2274.8268174675104!2d5.080843392040195!3d51.55510687331056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d51.555375999999995!2d5.0831554!4m5!1s0x47c6bfd173488515%3A0xdfc428830d73fad2!2sNieuwlandstraat%2059%2C%205038%20SM%20Tilburg!3m2!1d51.555386!2d5.08293!5e0!3m2!1snl!2snl!4v1728334039805!5m2!1snl!2snl"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Maps"
              ></iframe>
            </div>

            <div>
              <div className="addressCol"></div>
              <div className="addressCol">
                <h3>Satiata Tilburg</h3>
                <p>Nieuwlandstraat 59</p>
                <p>5038 SM</p>
                <br></br>
                <div id="openingWrapper">
                  <div className="openingCol">
                    <p>Ma</p>
                    <p>Di</p>
                    <p>Wo</p>
                    <p>Do</p>
                    <p>Vr</p>
                    <p>Za</p>
                    <p>Zo</p>
                  </div>

                  <div className="openingCol">
                    <p>Gesloten</p>
                    <p>Gesloten</p>
                    <p>17:00 - 23:00</p>
                    <p>17:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                    <p>13:00 - 23:00</p>
                  </div>
                </div>
                <br></br>
                <a href="tel:+31638732943">
                <p>
                  <img src={phone} alt=""></img>06-38732943
                </p>
                </a>
                <a href="mailto:satiatatilburg@gmail.com">
                <p>
                  <img src={mail} alt=""></img>satiatatilburg@gmail.com
                </p>
                </a>
                <a href="https://www.instagram.com/satiata.tilburg/"  target="_blank" rel="noopener noreferrer" >
                <p>
                  <img src={insta} alt=""></img>@satiata.tilburg
                </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="bottomSpacer">
        <img src={logo} alt=""></img>
        <p>© Pulcinos – Alle rechten voorbehouden</p></div>
    </div>
  );
}

export default Home;
